import { ethers } from "ethers"
import React, { useState } from "react"
import "./TransactionModals.scss"
import { useConnectWallet } from '@web3-onboard/react'
const weth9abi = require("../../assets/abis/weth9.json")


const TransactionModals = ({transactionStatus, cancelTransaction, scheduledTransaction, setWETH, sendOrder, ethBalance, setTransactionStatus}) => {
    const [conversionAmount, setConversionAmount] = useState(0)
    const [{ wallet } ] = useConnectWallet()
    const [infiniteApproval, setInfiniteApproval] = useState(false)


    const handleInfiniteApprovalChange = (event) => {
        setInfiniteApproval(event.target.checked)
    }
    
    const onChangeConversionAmount = (e) => {
        setConversionAmount(e.target.value)
    }

    const setMaxETH = () => {
        if(ethBalance - 0.01 < 0) {
            setConversionAmount(0)
        } 
        setConversionAmount((ethBalance - 0.01).toFixed(4))
    }

    const convertToWETH = async () => {
        let wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
        
        let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
        let signer = await ethersProvider.getSigner()   
        let contract = new ethers.Contract(wethAddress, weth9abi, signer)
        
        let amount = parseInt(conversionAmount * Math.pow(10, 18)).toString()
        let tx = await contract.deposit({value: amount})
        setTransactionStatus(102)
        await tx.wait()
        setTransactionStatus(103)
    }

    const convertToWMATIC = async () => {
        let wethAddress = "0x202C35e517Fa803B537565c40F0a6965D7204609"
        
        let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
        let signer = await ethersProvider.getSigner()   
        let contract = new ethers.Contract(wethAddress, weth9abi, signer)
        
        let amount = parseInt(conversionAmount * Math.pow(10, 18)).toString()
        let tx = await contract.deposit({value: amount})
        setTransactionStatus(102137)
        await tx.wait()
        setTransactionStatus(103137)
    }

    return (
        <div>
            {((transactionStatus > 0 && transactionStatus < 7) || transactionStatus === 11 ||  transactionStatus === 69 || transactionStatus === 101 || transactionStatus === 101137 || transactionStatus === 102137 || transactionStatus === 103137 ||  transactionStatus === 102 || transactionStatus === 103) && (
            <div>
                <div className="wrapper-bg" onClick={cancelTransaction}></div>
                <div className="modal-confirm">
                    {/* swap screen */}
                    {transactionStatus === 1 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Allow token to interact with smart contract. If you select infinite approval you only have to do this approval once.</p>
                    <hr/>
                    <label className="switch infin-switch">
                            <input type="checkbox" defaultChecked={infiniteApproval} onChange={handleInfiniteApprovalChange}/>
                            <span className="slider round"></span>
                    </label>
                    <p className="infinite-approval">Infinite approval for {scheduledTransaction.baseToken.symbol} (optional)</p>


                    <button className="btn btn-confirm mid" onClick={() => sendOrder(scheduledTransaction.baseToken, scheduledTransaction.baseAmount, scheduledTransaction.quoteToken, scheduledTransaction.quoteAmount, scheduledTransaction.enableWhiteList, scheduledTransaction.whiteListAddress, scheduledTransaction.timeAmount, scheduledTransaction.timeUnit, infiniteApproval, scheduledTransaction.enableMessage, scheduledTransaction.messageContent, scheduledTransaction.reviewRequest)}>Approve Transaction</button>
                    </React.Fragment>
                    )}
                    {/* otc screen */}
                    {transactionStatus === 11 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Allow token to interact with smart contract. If you select infinite approval you only have to do this approval once.</p>
                    <hr/>
                    <label className="switch infin-switch">
                            <input type="checkbox" defaultChecked={infiniteApproval} onChange={handleInfiniteApprovalChange}/>
                            <span className="slider round"></span>
                    </label>
                    <p className="infinite-approval">Infinite approval for {scheduledTransaction.quoteToken.symbol} (optional)</p>
                    <button className="btn btn-confirm mid" onClick={() => sendOrder(scheduledTransaction.baseToken, scheduledTransaction.baseAmount, scheduledTransaction.quoteToken, scheduledTransaction.quoteAmount, scheduledTransaction.enableWhiteList, scheduledTransaction.whiteListAddress, scheduledTransaction.timeAmount, scheduledTransaction.timeUnit, infiniteApproval, scheduledTransaction.enableMessage, scheduledTransaction.messageContent, scheduledTransaction.reviewRequest)}>Approve Transaction</button>
                    </React.Fragment>
                    )}
                    {transactionStatus === 2 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Approval request submitted to the blockchain. </p>
                    <div className="center-spin"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                    </React.Fragment>
                    )}
                    {transactionStatus === 3 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Now you will sign the transaction. There are no fees associated with. <br></br><br></br> By signing the transaction you confirm that you are not a US natural person or located in an OFAC sanctioned region.</p>
                    <button className="btn btn-confirm mid" onClick={() => sendOrder(scheduledTransaction.baseToken, scheduledTransaction.baseAmount, scheduledTransaction.quoteToken, scheduledTransaction.quoteAmount, scheduledTransaction.enableWhiteList, scheduledTransaction.whiteListAddress, scheduledTransaction.timeAmount, scheduledTransaction.timeUnit, infiniteApproval, scheduledTransaction.enableMessage, scheduledTransaction.messageContent, scheduledTransaction.reviewRequest)}>Sign Transaction</button>
                    </React.Fragment>
                    )}
                    {transactionStatus === 4 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Signing Transaction and creating offer. </p>
                    <div className="center-spin"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                    </React.Fragment>
                    )}
                     {transactionStatus === 5 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    { scheduledTransaction.quoteAmount > 0 &&
                        <React.Fragment>
                        <p>You will send {scheduledTransaction.quoteAmount} {scheduledTransaction.quoteToken.symbol} </p>
                        </React.Fragment>
                    }

                    <p>You will receive {scheduledTransaction.baseAmount} {scheduledTransaction.baseToken.symbol} </p>
                    <button className="btn btn-confirm mid" onClick={() => sendOrder(scheduledTransaction.baseToken, scheduledTransaction.baseAmount, scheduledTransaction.quoteToken, scheduledTransaction.quoteAmount, scheduledTransaction.enableWhiteList, scheduledTransaction.whiteListAddress, scheduledTransaction.timeAmount, scheduledTransaction.timeUnit, infiniteApproval, scheduledTransaction.enableMessage, scheduledTransaction.messageContent, scheduledTransaction.reviewRequest)}>Confirm Transaction</button>
                    </React.Fragment>
                    )}
                     {transactionStatus === 6 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Waiting for transaction to confirm </p>
                    <div className="center-spin"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                    </React.Fragment>
                    )}
                     {transactionStatus === 69 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p style={{textAlign: 'center'}}>Transaction confirmed! <br></br>Thanks for using TradeOffer </p>
                    <button className="btn btn-confirm mid" onClick={cancelTransaction }>Close</button>  
                    <a className="btn btn-confirm twitter-follow" href="https://twitter.com/TradeOffer_fi" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i>Follow us on Twitter</a>  
                    </React.Fragment>
                    )}
                    {/* convert ETH to WETH */}
                    {transactionStatus === 101 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>ETH needs to be converted to WETH before it can be used</p>
                    <button className="btn btn-confirm mid" onClick={setWETH}>Use WETH instead</button>
                    <hr/>
                    <p>ETH to WETH converter</p>
                    <input className="ethConvertInput" value={conversionAmount} onChange={onChangeConversionAmount}/>
                    <button className="ethConvertMaxButton" onClick={() => setMaxETH()}>max</button>
                    <p className="ethb">eth balance: {parseFloat(ethBalance).toFixed(4)}</p>
                    <button className="btn btn-confirm mid" onClick={convertToWETH}>Convert {conversionAmount || ''} ETH to {conversionAmount || ''} WETH</button>
                    {/* if weth already exists */}
                    
                    </React.Fragment>
                    )}
                    {/* convert ETH to WETH */}
                    {transactionStatus === 101137 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>NEON needs to be converted to WNEON before it can be used</p>
                    <button className="btn btn-confirm mid" onClick={setWETH}>Use WNEON instead</button>
                    <hr/>
                    <p>NEON to WNEON converter</p>
                    <input className="ethConvertInput" value={conversionAmount} onChange={onChangeConversionAmount}/>
                    <button className="ethConvertMaxButton" onClick={() => setMaxETH()}>max</button>
                    <p className="ethb">neon balance: {parseFloat(ethBalance).toFixed(4)}</p>
                    <button className="btn btn-confirm mid" onClick={convertToWMATIC}>Convert {conversionAmount || ''} NEON to {conversionAmount || ''} WNEON</button>
                    {/* if weth already exists */}
                    
                    </React.Fragment>
                    )}
                    {transactionStatus === 102 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Converting ETH to WETH. Waiting for transaction confirmation.</p>
                    <div className="center-spin"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>                    
                    </React.Fragment>
                    )}
                    {transactionStatus === 103 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Swap Success!</p>
                    <button className="btn btn-confirm mid" onClick={setWETH}>use WETH</button>                  
                    </React.Fragment>
                    )}
                    {transactionStatus === 102137 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Converting NEON to WNEON. Waiting for transaction confirmation.</p>
                    <div className="center-spin"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>                    
                    </React.Fragment>
                    )}
                    {transactionStatus === 103137 && ( 
                    <React.Fragment>
                    <i className="fa fa-close close-modal" onClick={cancelTransaction}></i>
                    <p>Swap Success!</p>
                    <button className="btn btn-confirm mid" onClick={setWETH}>use WNEON</button>                  
                    </React.Fragment>
                    )}
                </div>
            </div>
            )}
        </div>)
}

export default TransactionModals