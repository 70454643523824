import React, { useState, useEffect } from "react"
import { useRoutes } from "react-router-dom"
import Swap from "../pages/Swap/Swap"
import TokenFactory from "../pages/TokenFactory/TokenFactory"
import MyOffers from "../pages/MyOffers/MyOffers"
import OTC from "../pages/OTC/OTC"
import { ethers} from "ethers"
import NavBar from "./NavBar/NavBar"
import EnvContext from "../context/EnvContext"
import { HelmetProvider } from 'react-helmet-async';
// import Search from "./Search/Search"
import Footer from "./Footer/Footer"
import axios from "axios"

import { init, useAccountCenter, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'

import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import gnosisModule from '@web3-onboard/gnosis'

const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const gnosis = gnosisModule()
const walletConnect = walletConnectModule({
    qrcodeModalOptions: {
      mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
    },
    connectFirstChainId: true
})

const injected = injectedModule()

const onboard = init({
    wallets: [injected, walletConnect, coinbaseWalletSdk, gnosis],
    chains: [
      {
        id: '0x1',
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: `https://eth-mainnet.g.alchemy.com/v2/lzxhh2mr9m9eVPFD3mgFE2AUXRknF8V3`
      },
      {
        id: '1',
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: `https://rpc.flashbots.net`
      },
    ],
    appMetadata: {
        name: 'TradeOffer',
        icon: '<svg width="320" height="320" viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="320" height="320" fill="#1E1E1E"/><rect width="320" height="320" fill="#191918"/><circle cx="160.5" cy="160.5" r="102.5" fill="#1E1E1E"/><path d="M126.261 136.94V121.727H197.938V136.94H171.219V209H152.98V136.94H126.261Z" fill="white"/></svg>',
        logo: '<svg width="32" height="32" viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="320" height="320" fill="#1E1E1E"/><rect width="320" height="320" fill="#191918"/><circle cx="160.5" cy="160.5" r="102.5" fill="#1E1E1E"/><path d="M126.261 136.94V121.727H197.938V136.94H171.219V209H152.98V136.94H126.261Z" fill="white"/></svg>',
        description: 'TradeOffer',
        recommendedInjectedWallets: [
          { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
          { name: 'MetaMask', url: 'https://metamask.io' }
        ]
      }
})

const previouslyConnectedWallets = JSON.parse(
  window.localStorage.getItem('connectedWallets')
)

if (previouslyConnectedWallets && previouslyConnectedWallets[0]) {
  // auto connect "silently" and disable all onboard modals to avoid them flashing on page load
  onboard.connectWallet({
    autoSelect: { label: previouslyConnectedWallets[0], disableModals: true }
  })
}

const walletsSub = onboard.state.select('wallets')
 walletsSub.subscribe(wallets => {
  const connectedWallets = wallets.map(({ label }) => label)
  window.localStorage.setItem(
    'connectedWallets',
    JSON.stringify(connectedWallets)
  )
})

const App = () => {
    const [ip, setIP] = useState('');
    const [warningMessage, setWarningMessage] = useState('')
    const [block, setBlock] = useState('')
    const [baseUrl] = useState(window.location.hostname === 'localhost' ? 'http://localhost:8000' :'https://tradeoffer.fi')
    const [backendUrl] = useState(window.location.hostname === 'localhost' ? 'http://localhost:8000' :'https://sheltered-scrubland-70713.herokuapp.com' )
    const [currentNetwork, setCurrentNetwork] = useState(1);
    const updateAccountCenter = useAccountCenter()
    const [{ wallet } ] = useConnectWallet()
    // by default use neon


    const changeNetworkById = (networkId) => {
      setCurrentNetwork(networkId)
      window.localStorage.setItem(
        'LASTUSEDNETWORK',
        networkId
      )
    }

    useEffect(() => {
      if(wallet && wallet.provider) {
        const networkCheck = async () => {
          let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
          console.log(ethersProvider, "wallet provider")
          let network = await ethersProvider.getNetwork()
          
          console.log(network.chainId, "chain id")
          console.log(currentNetwork, "current network")
          // console.log(network.chainId)
          // console.log(currentNetwork)
          if(network.chainId !== currentNetwork) {
            let networkName = "Ethereum"
            let requestedChain = "0x1"
            if(currentNetwork === 245022934) {
              networkName = "Neon"
              requestedChain = "0xe9ac0d6"
            } 

            setWarningMessage(`Your wallet is connected to a different network than TradeOffer. Please connect to the ${networkName} network or change your network on the top right.`)


            console.log(requestedChain, "REQUEST")

            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: requestedChain }], // chainId must be in hexadecimal numbers
            });

            // window.location.reload()


          } else {
            setWarningMessage('')
          }
        }
        networkCheck()
      }
    }, [wallet, currentNetwork])

    updateAccountCenter({enabled: false})

    const getIpData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      setIP(res.data)
    }
  
    useEffect( () => {
      const blacklist = ['US', 'PRK', 'IRN', 'CUB', 'UKR']
      if(ip.country_code) {
        if(blacklist.indexOf(ip.country_code.toUpperCase()) !== -1) {
          setBlock(`You’re accessing TradeOffer.fi from the United States or another Restricted Jurisdiction. We are not able to provide you with our services at this time.`)
        } else {
          console.log('connection is allowed')
        }
      }
      
    }, [ip])

    useEffect( () => {
      getIpData()
    }, [])

    const RouterList = useRoutes([
        {
            path: '/',
            element: <Swap currentNetwork={currentNetwork} />,
        },
        {
            path: '/otc/:uuid',
            element: <OTC currentNetwork={currentNetwork} changeNetworkById={changeNetworkById} />
        },
        {
            path: '/offers/me',
            element: <MyOffers/>
        },
        {
          path: '/mint',
          element: <TokenFactory currentNetwork={currentNetwork} />
        }
    ])
    return (
    <div>
         <HelmetProvider>
                <EnvContext.Provider value={{backendUrl, baseUrl}}>
                        <NavBar changeNetworkById={changeNetworkById} currentNetwork={currentNetwork} />
                        { warningMessage.length > 0 && <div className="warning-message"><i className="fa fa-warning"></i> {warningMessage}</div> }
                        { block.length > 0 && 
                        <div className="block">{block}</div>}
                        { RouterList}
                        <Footer />
                </EnvContext.Provider>
        </HelmetProvider>
    </div>
    )
}

export default App
