import { createOrder } from '@airswap/utils'
import moment from "moment"
import React, {useContext, useEffect, useState} from "react"
// import OfferHolder from "../../components/OfferHolder/OfferHolder"
import SwapHolder from "../../components/SwapHolder/SwapHolder"
import { ethers} from "ethers"
import axios from 'axios'
import TransactionModals from '../../components/TransactionModals/TransactionModals'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EnvContext from '../../context/EnvContext'
import { useConnectWallet } from '@web3-onboard/react'
import './Swap.scss'
const CryptoJS = require('crypto-js');
const erc20ABI = require("../../assets/abis/erc20.json")

const Swap = ({currentNetwork}) => {
    const [{ wallet } ] = useConnectWallet()
    const [searchParams, setSearchParams] = useSearchParams();
    const [transactionStatus, setTransactionStatus] = useState(0)
    const [scheduledTransaction, setScheduledTransaction] = useState({})
    // eslint-disable-next-line
    const [reloadOffers, setReloadOffers] = useState(false)

    
    const navigate = useNavigate()
    const env = useContext(EnvContext)

    const cancelTransaction = () => {
        setTransactionStatus(0)
    }

    useEffect(() => {
        if(searchParams.get('a') === 'finish') {
            setTransactionStatus(69)
            setSearchParams('')
            window.confetti.start() 
            setTimeout(() => {
                window.confetti.stop()
            }, 3000)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const sendOrder = async (baseToken, baseAmount, quoteToken, quoteAmount, enableWhiteList, whiteListAddress, timeAmount, timeUnit, infiniteApproval, enableMessage, messageContent, reviewRequest) => {

        let expiryCalc =  moment().add(timeAmount, timeUnit).valueOf() 
        expiryCalc = parseInt(expiryCalc / 1000)

        
        let swapContractAddress = "0x8fca5f8455ad1e423761ef7a50d62ef407a4a9c0"
        if(currentNetwork === 245022934) {
            swapContractAddress = "0x910ce66b07f26b0c5083f5bc022789206c4f5a8a"
        }
        let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
        let baseCurrencyContract = new ethers.Contract(baseToken.address, erc20ABI, ethersProvider)
        
        let allowance = await baseCurrencyContract.allowance(wallet.accounts[0].address, swapContractAddress)
        let totalSupply = await baseCurrencyContract.totalSupply()
        totalSupply = totalSupply.toString() + '0'
        allowance = ethers.utils.formatUnits(allowance, baseToken.decimals)

        setScheduledTransaction({baseToken, baseAmount, quoteToken, quoteAmount, enableWhiteList, whiteListAddress, timeAmount, timeUnit, infiniteApproval, enableMessage, messageContent, reviewRequest})

        let txFee = (baseAmount / 10000) * 10
        let amountNeeded = parseFloat(baseAmount) + parseFloat(txFee)
        if(allowance < amountNeeded) {
            // show user information about approving
            if(transactionStatus !== 1) {
                setTransactionStatus(1)
                return 
                
            } 
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            let signer = await ethersProvider.getSigner()          
            let baseCurrencyContractSigner = new ethers.Contract(baseToken.address, erc20ABI, signer)

            // if infinite approval is set, use totalsupply for approval
            // else use just required amount

            let requiredAmount = ethers.utils.parseUnits(amountNeeded.toString(), baseToken.decimals)
            if(infiniteApproval) requiredAmount = totalSupply.toString()

            const tx = await baseCurrencyContractSigner.approve(swapContractAddress,  requiredAmount)
            setTransactionStatus(2)
            await tx.wait();
            setTransactionStatus(3)

        } else { 
            // show user information about signing 
            if(transactionStatus !== 3) {
                setScheduledTransaction({baseToken, baseAmount, quoteToken, quoteAmount, enableWhiteList, whiteListAddress, timeAmount, timeUnit, infiniteApproval, enableMessage, messageContent, reviewRequest})
                setTransactionStatus(3)
                return 
            } 
            // subtract fee from baseAmount
            const protocolFee = 10
            let senderWallet = '0x0000000000000000000000000000000000000000'
            if(enableWhiteList === true) {
                senderWallet = whiteListAddress
            }

            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
            let signer = await ethersProvider.getSigner()

            let orderObject = {
                expiry: expiryCalc,
                nonce: Date.now(),
                signerWallet: wallet.accounts[0].address,
                signerToken: baseToken.address,
                signerAmount: ethers.utils.parseUnits(baseAmount, baseToken.decimals),
                protocolFee: protocolFee,
                senderWallet: senderWallet,
                senderToken: quoteToken.address, 
                senderAmount:  ethers.utils.parseUnits(quoteAmount, quoteToken.decimals),
            }

            
            let address = ethers.utils.getAddress(swapContractAddress)
        
            let hash   = CryptoJS.SHA256(messageContent);
            let buffer = Buffer.from(hash.toString(CryptoJS.enc.Hex), 'hex');
            let unsignedOrder = createOrder(orderObject)

            if(reviewRequest || messageContent.length > 0) {
                unsignedOrder.hashedNote = buffer
            }
            else {
                unsignedOrder.hashedNote = ethers.utils.formatBytes32String("")
            }

            let sig = null 

            try {
                sig = await signer._signTypedData(
                    {
                        name: 'TROF',
                        version: '5',
                        chainId: currentNetwork,
                        verifyingContract: address,
                    },
                    { Order: [
                        { name: 'hashedNote', type: 'bytes32'},
                        { name: 'nonce', type: 'uint256' },
                        { name: 'expiry', type: 'uint256' },
                        { name: 'signerWallet', type: 'address' },
                        { name: 'signerToken', type: 'address' },
                        { name: 'signerAmount', type: 'uint256' },
                        { name: 'protocolFee', type: 'uint256' },
                        { name: 'senderWallet', type: 'address' },
                        { name: 'senderToken', type: 'address' },
                        { name: 'senderAmount', type: 'uint256' }]
                    },
                    unsignedOrder
                    )
    
            } catch(e) {
                console.log(e)
            }

              const { r, s, v } = ethers.utils.splitSignature(sig)
             
              let body = {
                order: {
                    nonce: unsignedOrder.nonce,
                    expiry: unsignedOrder.expiry,
                    signerWallet: unsignedOrder.signerWallet,
                    signerToken: unsignedOrder.signerToken,
                    signerAmount: unsignedOrder.signerAmount.toLowerCase(),
                    protocolFee: unsignedOrder.protocolFee,
                    senderWallet: unsignedOrder.senderWallet.toLowerCase(),
                    senderToken: unsignedOrder.senderToken,
                    senderAmount: unsignedOrder.senderAmount,
                    enableEncryptedNote: false,
                    requiredAcceptEncryptedNote: false,
                    plainNote: '',
                    network: currentNetwork,
                    v,
                    r,
                    s
                }
              }

              if(enableMessage) {
                body.order.plainNote = messageContent
                body.order.enableEncryptedNote = true
                if(reviewRequest) body.order.requiredAcceptEncryptedNote = true
            }
            
              await setTransactionStatus(4)
              let res = await axios.post(`${env.backendUrl}/order/new`, body)
              setScheduledTransaction({...scheduledTransaction, uuid: res.data.uuid})

              navigate(`/otc/${res.data.uuid}`)
              setReloadOffers(true)
              window.confetti.start() 
              setTimeout(() => {
                  window.confetti.stop()
              }, 3000) 

        }        
    }   

    return (
    <div className="swap-page">

        <div className="container col-md-6">
            <h3>Secure On-Chain P2P Trading </h3>
            <h5>Create trading offers and easily share them with a generated link, publicly or privately. <br></br>Secure and non-custodial.</h5>
        </div>
        <div className="container">
            <div className="row">
            <div className="col-md-6">

                <TransactionModals scheduledTransaction={scheduledTransaction} transactionStatus={transactionStatus} sendOrder={sendOrder} cancelTransaction={cancelTransaction}/>
                <p style={{marginTop: '20px', color: '#c0c0c0', fontWeight: '300'}}>Create New Offer</p>

                <SwapHolder currentNetwork={currentNetwork} createOrder={sendOrder}/>

                </div>
                <div className="col-md-6">
                    <p style={{marginTop: '20px', color: '#c0c0c0', fontWeight: '300'}}>Create an Offer in 3 steps</p>
                    {/* <OfferHolder reloadOffers={reloadOffers} /> */}

                    <div className="works">
                        <div className="number-step">Step 1</div>
                        <div className="text-holder">
                            <p> Specify the terms of your trade</p>
                            <p className="subtxt">Trades can be made public or private, specify your terms to make it a perfect deal.</p>
                        </div>
                    </div>
                    <div className="works">
                        <div className="number-step">Step 2</div>
                        <div className="text-holder">
                            <p> Sign message and set approval for your order.  </p>
                            <p className="subtxt">Your funds will only be sent upon release of counterparty tokens. The smart contract will never your funds.</p>
                        </div>
                    </div>
                    <div className="works">
                    <div className="number-step">Step 3</div> 
                        <div className="text-holder">
                            <p>Receive a shareable link and send it to your counterparty. </p>
                            <p className="subtxt">Links can easily be shared on platforms such as Telegram.</p>
                        </div>
                    </div>

                    <p style={{marginTop: '20px', color: '#c0c0c0', fontWeight: '300'}}>TradeOffer protects your trades</p>
                    <div className="works info">
                        <div className="number-step check"><i className="fa fa-check" style={{'fontSize': '12px'}}></i></div>
                        <div className="text-holder check">
                            <p> Non Custodial Smart Contract</p>
                            <p className="subtxt info">The contract never holds funds on your behalf and is not upgradeable. Trades are executed the moment your counterparty accepts the trade.</p>
                        </div>
                    </div>
                    <div className="works info" >
                        <div className="number-step check"><i className="fa fa-check"  style={{'fontSize': '12px'}}></i></div>
                        <div className="text-holder check">
                            <p>Verified and Open Source</p>
                            <p className="subtxt info">The contract is verified on Etherscan and visible on the Ethereum blockchain .</p>
                        </div>
                    </div>



                </div>

            </div>
        </div>
        
    </div>)
}

export default Swap
