import React from "react";
import { useEffect, useContext, useState } from "react"
import "./MyOffers.scss"
import axios from "axios"
import MyOffer from "../../components/MyOffer/MyOffer"
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import EnvContext from "../../context/EnvContext"
import { useConnectWallet } from '@web3-onboard/react'

const MyOffers = () => {
    const [{ wallet }, connect ] = useConnectWallet()
    const env = useContext(EnvContext);
    const [userOrders, setUserOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {    
        const getOrders = async () => {
            setLoading(true)
            let res = await axios.get(`${env.backendUrl}/order/user?address=${wallet.accounts[0].address}`)
            setUserOrders(res.data.orders)
            setLoading(false)
        }
        if(wallet && wallet.accounts.length > 0) getOrders()
    }, [wallet, env.backendUrl])

    return (
        <div className="myOffers">
           <div className="container col-md-6 offset-md-3">
                { wallet === null && (
                <div className="offerWarning">
                    <p>To view all your offers you need to connect your wallet</p>
                    <button onClick={() => connect()}className="btn btn-confirm">Connect Wallet</button>
                </div>
                )}
                { (wallet && wallet.accounts.length > 0) && 
                <div>

                {loading && <Spinner/>}
                { !loading && userOrders.length === 0 && 
                <div className="container">
                    <h5 style={{marginBottom: '20px', fontWeight: '200', paddingTop: '50px'}}>You have not placed any trades</h5>
                    <button onClick={() => navigate('/')}className="btn btn-confirm">Create New Trade</button>
                </div>
                }
                { userOrders.length > 0 && 

                // render all offers
                userOrders.map((order, index) => { return <MyOffer type="me" style={{marginBottom: '15px'}} key={index} object={order}/>}) }
                </div>
                }
           </div>
        </div>
    )
}

export default MyOffers