import React, { useState, useEffect, useContext} from "react"
import SwapInput from "../../components/SwapInput/SwapInput"
import EnvContext from "../../context/EnvContext"
import axios from "axios"
import { ethers} from "ethers"
import ReactImageFallback from "react-image-fallback";
import './SwapHolder.scss'
import Spinner from "../../components/Spinner/Spinner"
import TransactionModals from "../TransactionModals/TransactionModals"
import { useConnectWallet } from '@web3-onboard/react'


const genericErc20Abi = require("../../assets/abis/erc20.json")
const neonAPI = "https://neon-proxy-mainnet.solana.p2p.org/"
const ethereumAPI = "https://eth-mainnet.g.alchemy.com/v2/lzxhh2mr9m9eVPFD3mgFE2AUXRknF8V3"

const SwapHolder = ({createOrder, currentNetwork}) => {
    const [{ wallet }, connect] = useConnectWallet()
    const [transactionStatus, setTransactionStatus] = useState()
    const [timeUnit, setTimeUnit] = useState('hours')
    const [timeAmount, setTimeAmount] = useState('24')
    const [selectionIsOpen, setSelectionIsOpen] = useState(false)
    
    const [marketPrice, setMarketPrice] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [enableWhiteList, setEnableWhiteList] = useState(false)
    const [enableMessage, setEnableMessage] = useState(false)
    const [messageContent, setMessageContent] = useState("")
    const [reviewRequest, setReviewRequest] = useState(false)
    const [whiteListAddress, setWhiteListAddress] = useState('')
    const [loadingTokenList, setLoadingTokenList] = useState(true)
    const [loadingCustomToken, setLoadingCustomToken] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [baseToken, setBaseToken] = useState({address: '', balance: 0, symbol: "-", logoURI: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png"})
    const [quoteToken, setQuoteToken] = useState({address: '', balance: 0, symbol: "-", logoURI: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png"})
    const [baseAmount, setBaseAmount] = useState(0)
    const [ethBalance, setEthBalance] = useState(0)
    const [balancesLoaded, setBalancesLoaded] = useState(false)
    const [quoteAmount, setQuoteAmount] = useState(0)
    const [activeType, setActiveType] = useState("base")
    const [tokenList, setTokenList] = useState([])
    const [orderValidation, setOrderValidation] = useState({
        valid: false,
        action: '',
        message: '',
        params: []
    })

    const env = useContext(EnvContext)

    const safeRoundDown = (number) => {
        return Math.floor(parseFloat(number).toFixed(6) * 100000) / 100000
    }

    const setSafeBaseAmount = (amount) => {
        if(amount > 0) {
            let baseAmount = safeRoundDown(amount).toString()
            setBaseAmount(baseAmount)
        }
        setBaseAmount(amount)
    }

    const cancelTransaction = () => {
        setTransactionStatus(0)
    }

    const getAllTokens = async () => {
        setIsLoading(true)
        let tokenUrl = `${env.backendUrl}/tokenlist`
        try {
            let tokens = await axios.get(tokenUrl)
            setIsLoading(false)
            setLoadingTokenList(false)
            if(tokens.data.tokens.length > 0) {
                const tokenList = []
                // change this when chainid changes
                let filteredTokens = tokens.data.tokens.filter((token) => token.chainId === currentNetwork)
                let tempList = [...tokenList]
                filteredTokens.forEach(token => tempList.push({...token, balance: 0}))
                await setTokenList(tempList)
                setIsLoading(false)
            }
  
        } catch (e) {
            setIsLoading(false)
            console.log("cannot get tokenlist")
        }
    }

    const lookupToken = async (address) => {
        setLoadingCustomToken(true)
        const url = `https://api.ethplorer.io/getTokenInfo/${address}?apiKey=EK-fZgKF-SytnSAs-jsYLL`
        try {
            if(currentNetwork === 1) {
                let res = await axios.get(url)
                if(!res.data.decimals) throw new Error("invalid decimals")
                let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
                let contract = new ethers.Contract(address, genericErc20Abi, ethersProvider.provider)
    
                let contractRes = await contract.balanceOf(wallet.accounts[0].address)
                let balance = ethers.utils.formatUnits(contractRes, res.data.decimals)
    
                let tempList = [...tokenList]
                tempList = tempList.filter(item => !item.itemPulledExternal)
    
                tempList.push({
                    balance: balance,
                    logoURI: res.data.image ? `https://ethplorer.io/${res.data.image}` : `https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png`,
                    name: res.data.name,
                    address: res.data.address,
                    symbol: res.data.symbol,
                    itemPulledExternal: true
                })
    
                setTokenList(tempList)
                setLoadingCustomToken(false)
            } else {
                throw Error("not ethereum")
            }
        } catch (e) {
            console.log("CATCH LIST")
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            let contract = new ethers.Contract(address, genericErc20Abi, ethersProvider)
            let contractRes = await contract.balanceOf(wallet.accounts[0].address)

            let decimals = await contract.decimals()
            let symbol = await contract.symbol()
            let balance = ethers.utils.formatUnits(contractRes, decimals)

            let tempList = [...tokenList]
            let newList = tempList.filter((item => item.itemPulledExternal !== true ))
            newList.push({
                balance: parseInt(balance).toFixed(2),
                decimals: parseInt(decimals),
                logoURI: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png',
                name: address.slice(0, 10),
                symbol: symbol,
                address: address,
                itemPulledExternal: true
            })
            
            setTokenList(newList)
            setLoadingCustomToken(false)
        }
    }

    const whiteListIsValid = (wal) => {
        if(wal.length !== 42) {
            return false
        }
        if(wallet && wallet.accounts.length > 0 && wal.toLowerCase() === wallet.accounts[0].address.toLowerCase()) {
            return false
        } 
        return true
    }
    
    const toggleSelection = (type) => {
        if(wallet === null || wallet.accounts.length === 0) connect()
        setActiveType(type)
        setSelectionIsOpen(!selectionIsOpen)
    }

    const handleMessageContentChange = (event) => {
        setMessageContent(event.target.value)
    }

    const handleInput = (event) => {
        setSearchText(event.target.value)
    }

    const amountWithTxFee = (amount) => {
        const fee = 100 // bips
        let txFee = ((amount / 10000) * fee)
        let newAmount = amount - txFee
        return newAmount
    }

    const handleMessageCheckBox = (event) => {
        setEnableMessage(event.target.checked)
        
        if(event.target.checked === true) {
            handleWhiteListCheckbox({target: {checked: true}})
        }
    }

    const handleReviewRequestCheckBox = (event) => {
        setReviewRequest(event.target.checked)
    }

    const handleWhiteListInput = (event) => {
        setWhiteListAddress(event.target.value)
    }

    const handleWhiteListCheckbox = (event) => {
        setEnableWhiteList(event.target.checked)
    }

    const setWETH = async () => {
        try {
            if(currentNetwork === 1) {

                let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
                let wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
                let contract = new ethers.Contract(wethAddress, genericErc20Abi, ethersProvider)
                let tempList = [...tokenList]
                let weth = tempList.find(item => item.symbol === 'WETH')
                let bal = await contract.balanceOf(wallet.accounts[0].address)
                weth.balance = ethers.utils.formatUnits(bal, 18)
                cancelTransaction()
                setTokenList(tempList)
                setBaseToken(weth)
            } else if (currentNetwork === 245022934) {
                let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
                let wethAddress = "0x202c35e517fa803b537565c40f0a6965d7204609"
                let contract = new ethers.Contract(wethAddress, genericErc20Abi, ethersProvider)
                let tempList = [...tokenList]
                let weth = tempList.find(item => item.symbol === 'WNEON')
                let bal = await contract.balanceOf(wallet.accounts[0].address)
                weth.balance = ethers.utils.formatUnits(bal, 18)
                cancelTransaction()
                setTokenList(tempList)
                setBaseToken(weth)
            }


            setSelectionIsOpen(false)
        } catch (e) {
            console.log("cannot retrieve balance")
        }
    }

    const convertETH = () => {
        toggleSelection()
        if(currentNetwork === 1) {
            // convert native ETH to WETH
            setTransactionStatus(101)
        } else if (currentNetwork === 245022934) {
            // convert native matic to WMATIC
            setTransactionStatus(101137)
        }
    }

    const handleTimeAmountInputBlur = (event) => {
        if(event.target.value === '' || event.target.value === '0' || parseInt(event.target.value) < 1) 
        setTimeAmount('1')
    }

    const handleTimeAmountInput = (event) => {
        setTimeAmount(event.target.value)
    }
    const handleTimeInput = (event) => {
        setTimeUnit(event.target.value)
    }


    useEffect(() => {

        const getPricing = async () => {
            try {

                let url = `https://api.1inch.io/v5.0/${currentNetwork}/quote?`
                url += `fromTokenAddress=${baseToken.address}`
                url += `&toTokenAddress=${quoteToken.address}`
                url += `&amount=${(parseFloat(baseAmount) * Math.pow(10, baseToken.decimals)).toLocaleString('fullwide', {useGrouping:false}) }`

                let res = await axios.get(url)
                let tokenExpectedOutput = res.data.toTokenAmount
    
                let resultAggregator = tokenExpectedOutput / Math.pow(10, quoteToken.decimals)
    
                let actual = quoteAmount
        
                let diff = ((actual - resultAggregator) / resultAggregator) * 100
                return [diff, resultAggregator]
    
            } catch (e) {
                console.log(e)
            }
        }

        if(baseToken.address && quoteToken.address && baseAmount && quoteAmount) {
            const run = async () => {
                let res1inch = await getPricing()
                if(res1inch && res1inch[0] && res1inch[1]) {
                    setDiscount(res1inch[0])
                    setMarketPrice(res1inch[1])
                }
            }
            run()
        }
    }, [baseToken, quoteToken, baseAmount, quoteAmount, currentNetwork])

    const setToken = (token) => {
        activeType === "base" ? setBaseToken(token) : setQuoteToken(token)
        toggleSelection()
        setSearchText("")
        // calculate pricing
        // check if we have both base and quote
    }

    const filteredTokens = tokenList.filter((token) => {
        return token.name.toLowerCase().includes(searchText.toLowerCase()) || 
               token.address.toLowerCase().includes(searchText.toLowerCase()) || 
               token.symbol.toLowerCase().includes(searchText.toLowerCase())
    })


    const invoke = (action, params) => { 
        if(action === 'connect') connect()
        if(action === 'createOrder') {
            createOrder(...params)
        }
    }
    const validateOrder = () => {
        // wallet is disconnected
        if(wallet === null) {
            setOrderValidation({
                valid: false, 
                message: 'Connect wallet',
                action: 'connect',
                params: []
            })
            return         
        } 

        // ensure that the numbers are not smaller than 0.000001
        const getDecimals = (number) => {
            if(!number) return 0
            return number.split('.')[1]?.length || 0;
        }

        if(getDecimals(baseAmount) > 6 || getDecimals(quoteAmount) > 6) {
            setOrderValidation({
                valid: false, 
                message: 'Too many decimals, please round your amount',
                action: '',
            })
            return
        }

        // if private message is enabled, the whitelist also needs to be enabled
        if(enableMessage === true) {
            // message needs to be under 2000 characters
            if(messageContent.length === 0) {
                setOrderValidation({
                    valid: false, 
                    message: 'Message cannot be empty',
                    action: ''
                })
                return
            }

            if(messageContent.length > 2000) {
                setOrderValidation({
                    valid: false, 
                    message: 'message needs to be less than 2000 characters.',
                    action: ''
                })
                return 
            }
            if(enableWhiteList === false) {
                setOrderValidation({
                    valid: false, 
                    message: 'whitelist needs to be enabled to send private message',
                    action: ''
                })
                return
            }
        }
   
        // whitelist is enabled and invalid
        if(enableWhiteList === true && !whiteListIsValid(whiteListAddress)) {
            if(whiteListAddress.length === 0) {
                setOrderValidation({valid: false,  message: 'Waiting for address input', action: '', params: [] })
                return
            } else {
                setOrderValidation({valid: false,  message: 'Not a valid Ethereum address', action: '', params: [] })
                return
            }
            
        }

        // whitelist is valid or disabled
        if(enableWhiteList === false || (enableWhiteList === true &&  whiteListIsValid(whiteListAddress) )) {
            if(safeRoundDown(baseAmount)  <= safeRoundDown(amountWithTxFee(baseToken.balance))) {
                // user has correct amount of money in wallet
                if((wallet && wallet.accounts.length > 0) && (baseToken.address.length > 0 && quoteToken.address.length > 0 && baseAmount > 0 && (parseFloat(quoteAmount) > 0 ))) {
                    setOrderValidation({
                        valid: true, 
                        message: 'Confirm Order',
                        action: 'createOrder',
                        params: [baseToken, baseAmount, quoteToken, quoteAmount, enableWhiteList, whiteListAddress, timeAmount, timeUnit, false, enableMessage, messageContent, reviewRequest]
                    })
                    return 
                } 


                // there is not counter offer, ensure whitelist is enabled
                if((wallet && wallet.accounts.length > 0) && (baseToken.address.length > 0 && baseAmount > 0 && parseFloat(quoteAmount) === 0 && whiteListIsValid(whiteListAddress))) {
                    setOrderValidation({
                        valid: true, 
                        message: 'Confirm Order (receive nothing in return)',
                        action: 'createOrder',
                        params: [baseToken, baseAmount, baseToken, '0', enableWhiteList, whiteListAddress, timeAmount, timeUnit, false, enableMessage, messageContent, reviewRequest]
                    })
                    return 
                }
            }
        }

        // transaction not finished
        if(!baseToken.address.length > 0 || !baseAmount > 0 || !quoteAmount || quoteAmount < 0 || baseAmount < 0 ) {
            setOrderValidation({
                valid: false, 
                message: 'Select tokens and amount',
                action: '',
                params: []
            })
            return
        }

        // if the user wants to give away tokens, the whitelist needs to be set
        if (baseToken.address.length > 0 && baseAmount > 0 && parseFloat(quoteAmount) === 0 && !whiteListIsValid(whiteListAddress) ) {
            setOrderValidation({
                valid: false, 
                message: 'Whitelist required ',
                action: '',
                params: []
            })
            return 
        }

        // check if theres enough tokens in wallet
        if(safeRoundDown(baseAmount) > safeRoundDown(amountWithTxFee(baseToken.balance))) {
            setOrderValidation({
                valid: false, 
                message: 'not enough tokens in wallet',
                action: ''
            })
            return
        }

        // transaction is valid
        setOrderValidation({
            valid: true, 
            message: 'confirm order',
            action: ''
        })
    }

    useEffect(() => {
        validateOrder() 
    }, [enableWhiteList, enableMessage, messageContent, reviewRequest, baseToken, quoteToken, baseAmount, quoteAmount, wallet, whiteListAddress]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setBalancesLoaded(false)
    }, [wallet, currentNetwork])

    useEffect(() => {
        // get native token balance (eg ETH, MATIC)
        const getEthereumBalance = async () => {
            try {
                let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
                let balance = await ethersProvider.getBalance(wallet.accounts[0].address);
                let balanceNormalized = ethers.utils.formatEther(balance)
                let tempList = [...tokenList]
                if(currentNetwork === 1) {
                    tempList = tempList.filter(token => token.symbol !== 'ETH')
                    tempList.unshift({
                        balance: balanceNormalized,
                        logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
                        decimals: 18,
                        name: 'Ethereum',
                        symbol: 'ETH',
                        address: '0xab',
                    })
                } else if (currentNetwork === 245022934) {
                    tempList = tempList.filter(token => token.symbol !== 'NEON')
                    tempList.unshift({
                        balance: balanceNormalized,
                        logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
                        decimals: 18,
                        name: 'NEON',
                        symbol: 'NEON',
                        address: '0xab',
                    })
                }
                setEthBalance(balanceNormalized)
                setTokenList(tempList)
            } catch (e) {
                console.log("error in native balance")
                console.log(e)
            }
    }

        const getAllBalances = async () => {
        if(tokenList.length > 0 && wallet && !balancesLoaded) {
            // if wallet is connected, get all the balances
            setSearchText("")
            setBaseToken({address: '', balance: 0, symbol: "-", logoURI: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png"})
            setQuoteToken({address: '', balance: 0, symbol: "-", logoURI: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png"})
            const runPromises = async(promiseArray) => {
                try {
                    let res = await Promise.all(promiseArray)
                    console.log(res, "RESPONSE")
                    let cloneArray = [...tokenList]
                    let skipFirstItem = false
                    if((cloneArray[0] && cloneArray[0].symbol === 'ETH') || (cloneArray[0] && cloneArray[0].symbol === 'NEON')) {
                        skipFirstItem = true
                    }
                    res.forEach((balance, index) => {
                        cloneArray[skipFirstItem ? index + 1 : index].balance = ethers.utils.formatUnits(balance, cloneArray[skipFirstItem ? index + 1 : index].decimals)
                    })
                    setTokenList(cloneArray)
                    setBalancesLoaded(true)
                    getEthereumBalance()
                    setLoadingTokenList(false)
                } catch (e) {
                    setLoadingTokenList(false)
                    console.log(e)
                }
            }
            let promiseArray = []
            tokenList.forEach(token => {
                let ethersProvider = null
                if(currentNetwork === 245022934) {
                    ethersProvider = new ethers.providers.JsonRpcProvider(neonAPI);
                } else if (currentNetwork === 1) {
                    ethersProvider = new ethers.providers.JsonRpcProvider(ethereumAPI);
                } else {
                    throw new Error("invalid network id")
                }
                
                console.log("ETHERS PROVIDEr")
                console.log(ethersProvider)
                let contract = new ethers.Contract(token.address, genericErc20Abi, ethersProvider)

                // exclude ETH
                if(token.address !== '0xab') {
                    promiseArray.push(contract.balanceOf(wallet.accounts[0].address))
                }
            })
            setLoadingTokenList(true)
            await runPromises(promiseArray)
        }
    }
    const run = async () => {
        await getAllBalances()
        validateOrder()
    }

    run() 

    }, [tokenList, wallet, currentNetwork, balancesLoaded]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if(searchText.length === 42) {
            lookupToken(searchText)
        }
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const run = async () => {
            await getAllTokens()
        }
        run()
    }, [currentNetwork]) // eslint-disable-line react-hooks/exhaustive-deps


    
    return (
        <div>
            <TransactionModals transactionStatus={transactionStatus} setWETH={setWETH} ethBalance={ethBalance} setTransactionStatus={setTransactionStatus} cancelTransaction={cancelTransaction}/>
            {selectionIsOpen && 
                <div>
                    <div className="wrapper-bg" onClick={toggleSelection}></div>
                    {!loadingTokenList && (<div className="modal-picker">
                        <input className="search-input" placeholder="Search token or paste token address" value={searchText} onChange={handleInput} />
                        {loadingCustomToken && (<div className="modal-picker">
                            <p style={{'textAlign' : 'center', 'color' : 'grey'}}>Looking up custom token. This can take a few seconds</p>
                            <Spinner/>
                        </div>)}
                        { !isLoading && <div>
                            {filteredTokens.filter(token => {
                                // base and quote cannot be same
                                // quote can never be ethereum, instead offer weth
                                // 0xab is eth
                                if(activeType === 'base' && !quoteToken.address) return true
                                if(activeType === 'base' && quoteToken.address && quoteToken.address !== token.address) return true
                                if(activeType === 'quote' && !baseToken.address && token.address !== '0xab') return true
                                if(activeType === 'quote' && token.address !== '0xab' && baseToken.address && token.address !== baseToken.address) return true
                                return false
                            }).map((token, index) => { return ( 
                            <div className="item" key={index} onClick={()=> token.address !== '0xab' ? setToken(token) : convertETH() }>
                                <ReactImageFallback className="img" src={token.logoURI} alt="img" fallbackImage="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png" />
                                <div className="name">
                                {token.name.slice(0, 15)} / <span className="symbol">{token.symbol}</span>
                                </div> 
                                <div className="selector">{parseFloat(token.balance).toFixed(3)}</div>
                                { token.itemPulledExternal && <div className="warn"><p><i className="fa fa-warning"></i> This address is not verified as it doesn't appear in the default token list. Ensure you pasted the correct address.</p></div>}
                            </div>)
                        })}
                        </div>}
                    </div>)}
                    {loadingTokenList && (<div className="modal-picker">
                        <p style={{'textAlign' : 'center', 'color' : 'grey'}}>Loading list of tokens. This can take a few seconds</p>
                        <Spinner/>
                    </div>)}

                </div>
            }
            <div className="swap-holder">
                <p className="lab">You Send</p>
                <SwapInput toggleSelection={toggleSelection} canEdit={true} setActiveType={setActiveType} setAmount={setSafeBaseAmount} amount={baseAmount} token={baseToken} tokenType="base"/> 
                <p className="lab margin">You Receive</p>
 
                <SwapInput toggleSelection={toggleSelection} canEdit={true} setActiveType={setActiveType} setAmount={setQuoteAmount} amount={quoteAmount} token={quoteToken} tokenType="quote"/> 
                
                {(parseFloat(baseAmount) > 0 && parseFloat(quoteAmount) > 0 && baseToken.symbol !== '' && parseFloat(marketPrice) > 0 && parseFloat(discount) !== 0 && parseFloat(marketPrice) !== 0) && <p className="pricing-helper">Market rate for {baseAmount} {baseToken.symbol} is ~{marketPrice.toFixed(2)} {quoteToken.symbol}<br></br>
                        {(parseFloat(discount) > 0.01) && <span style={{'color' : '#977ab3'}}> (your offer is priced {discount.toFixed(2)}% above market)</span>}
                        {(parseFloat(discount) < -0.01) && <span style={{'color' : '#7eb37a'}}> (your offer is priced {discount.toFixed(2)}% below market)</span>}
                        {(parseFloat(discount) < 0.01 && parseFloat(discount) > -0.01) && <span> (your offer is priced close to market)</span>}
                </p>}

                <div className="whitelist-holder">
                    <div>
                        <label className="switch">
                            <input onChange={handleWhiteListCheckbox} checked={enableWhiteList} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <p className="whitelist-text">Private Order (recommended) </p>  
                    

                    <p className="deadline"><i className="fa fa-stopwatch"></i>  
                        <select value={timeUnit} onChange={handleTimeInput}>
                            <option value="hours">hour{timeAmount !== '1' ? 's' : ''}</option>
                            <option value="days">day{timeAmount !== '1' ? 's' : ''}</option>
                        </select>
                        <input type="number" min="1" value={timeAmount} onChange={handleTimeAmountInput} onBlur={handleTimeAmountInputBlur}/>
                    </p>
                </div>
                {enableWhiteList === true && <div className="whitelist-input">
                    <input spellCheck="false" onChange={handleWhiteListInput} style={{border: ((whiteListIsValid(whiteListAddress) || whiteListAddress.length === 0) ? '1px solid grey' : '1px solid #eb5959'),color: ( whiteListIsValid(whiteListAddress) || whiteListAddress.length === 0) ? '#15f778' : '#eb5959'}} placeholder="Input address to whitelist" />
                    { (whiteListAddress.length > 0 && !whiteListIsValid(whiteListAddress)) && (<small style={{color: '#eb5959'}}>invalid address</small>) }

                    { (wallet && wallet.accounts && wallet.accounts.length > 0 && (wallet.accounts[0].address.toLowerCase() === whiteListAddress.toLowerCase())) &&  (<small style={{color: '#eb5959'}}> - wallet cannot be same as sender</small>)}
                </div>}

                <div className="note-holder">
                    <div>
                        <label className="switch">
                            <input onChange={handleMessageCheckBox} value={enableMessage} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <p className="note-text"><span className="new-feature">New:</span> Attach private message to offer (private order required). </p>  
                </div>

                <div className="note-area">
                     
                {enableMessage === true && 
                    <div>
                        <p><strong style={{fontSize: '14px'}}>important: </strong>Messages are encrypted and stored off-chain while the transaction is pending. By default messages are destroyed upon trade acceptance.</p>
                        <textarea onChange={handleMessageContentChange} value={messageContent} placeholder="Type your message here."></textarea>
                        <br/>
                        </div>
                }
                </div>

                { enableMessage === true && <div>
                    <label className="switch">
                        <input onChange={handleReviewRequestCheckBox} value={reviewRequest} type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                <p className="note-text"><span className="new-feature">New:</span> Require message review. Taker has to review and accept the private message contents before accepting the offer. Upon acceptance the sha256 hash of the message is stored on the blockchain, the original message is destroyed (recommended for legal agreements) </p>  
                </div>
                }

                { (baseAmount > 0 && baseToken.symbol) &&  <p className="fees-info">Your fee: {((baseAmount / 10000).toFixed(5) * 10).toFixed(5)} {baseToken.symbol} (only paid when order is taken) <br></br>No fees for taker</p>}
                { (baseAmount === 0 || !baseToken.symbol) &&  <p className="fees-info">Your fee: 0.1% of sent amount (only paid when order is taken).  <br></br>No fees for taker</p>}
            </div>

            <div style={{backgroundColor: '#1b1b19', padding: '17px', paddingTop: '5px'}}>
                <p className="terms">By confirming to order you understand that swaps are fully decentralized and non-custodial. TradeOffer DAO will never take custody of the traded assets. Order will only be filled if a counterparty takes the offer.</p>

                <button className={orderValidation.valid ? 'btn btn-confirm' : 'btn btn-confirm dis'} style={{textAlign: 'right'}} onClick={() => invoke(orderValidation.action, orderValidation.params)}>{orderValidation.message}</button>
                </div>
        </div>
    )
}

export default SwapHolder