import React, { useState } from "react"
import "./SwapInput.scss"

const SwapInput = ({token, toggleSelection, tokenType, setAmount, canEdit, amount}) => {

    const [inputTouched, setInputTouched] = useState(false)

    const handleInput = (event) => {
        setInputTouched(true)
        setAmount(event.target.value)
    }

    const countDecimals = (value) => {
        if(Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0; 
    }

    const setMaximumAmount = () => {
        let decimals = countDecimals(parseFloat(amountWithTxFee(token.balance)));

        if(decimals > 6) {
            setAmount(amountWithTxFee(token.balance).toFixed(6))
        } else {
            setAmount(amountWithTxFee(token.balance).toFixed(decimals))
        }
    }

    const amountWithTxFee = (amount) => {
        if(tokenType === "quote") return parseFloat(amount) // no fee on quote   
        const fee = 100 // bips
        let txFee = ((amount / 10000) * fee)
        let newAmount = amount - txFee
        return newAmount
    }

    return (
        <div className="input-holder" style={{height: !canEdit ? '89px' : '', borderRadius: !canEdit ? '10px' : ''}}>
            { canEdit && <input type="number" value={amount ? amount : ""} placeholder="-" onChange={handleInput} />}
            { !canEdit && <input value={parseFloat(token.balance)} readOnly/>}
            <div className={canEdit ? 'currency' : 'currency noselect'} onClick={()=> toggleSelection(tokenType)}>
                <div className="selector-currency">
                    <img className="image-symbol" alt="currency" src={token.logoURI.length > 0 ? token.logoURI : 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Square_gray.svg/2048px-Square_gray.svg.png' }></img>
                    <div className="title-currency">{token.symbol.slice(0,5) || 'FFF'} 
                    
                    { (!canEdit && token.contractVerified) && <i className="fa fa-check-circle" style={{color: '#15f778'}}></i>}
                    { (!canEdit && !token.contractVerified) && <i className="fa fa-circle-info" style={{color: '#ff5700'}}></i>}

                    {canEdit && <i className="fa fa-chevron-down"></i>}
                    </div>
                </div>
            </div>


            { (canEdit && tokenType === "base") && (
            <React.Fragment>
                <p className="balance">Balance: { amountWithTxFee(token.balance).toFixed(4) || 0} 
                <strong onClick={() => setMaximumAmount()} style={{marginLeft: '5px', fontWeight: '500', cursor: 'pointer', color: 'white'}}>max</strong>
                </p>
            </React.Fragment>
            )}

            { (canEdit && tokenType === "quote" && inputTouched && amount <= 0) && (
            <React.Fragment>
                <p className="balance clickable">receiving nothing in return (set a whitelisted address) <br/>
                </p>
            </React.Fragment>
            )}      
    </div>)
}

export default SwapInput

