import "./Footer.scss"

const Footer = () => {
    return (<div className="footer clearfix">
        <div className="container clearfix">
            <div className="col-md-4">
                <h3>TradeOffer.fi by TradeOffer DAO</h3>
                <p>TradeOffer DAO is a DAO governed by its members, its technology does not ever hold funds on behalf of users or take custody of funds. We restrict services in OFAC sanctioned countries as well as the United States. </p>
            </div>
            <div className="col-md-2 offset-md-2">
                <h3>Platform</h3>
                <a href="/"><i className="fa fa-exchange" aria-hidden="true"></i> Create Offer</a>
                <a rel="noreferrer" target="_blank" href="https://tradeoffer.gitbook.io/tradeoffer-docs/"><i className="fa fa-book" aria-hidden="true"></i>Docs</a>
            </div>
            <div className="col-md-2">
            <h3>DAO Tools</h3>
                <a rel="noreferrer" target="_blank" href="https://etherscan.io/address/0x8fca5f8455ad1e423761ef7a50d62ef407a4a9c0" ><i className="fa-brands fa-ethereum"></i> Smart Contract Mainnet</a>
                <a rel="noreferrer" target="_blank" href="https://neonscan.org/address/0x910ce66b07f26b0c5083f5bc022789206c4f5a8a" ><i className="fa-brands fa-ethereum"></i> Smart Contract Neon</a>
                <a rel="noreferrer" target="_blank" href="https://tradeoffer.gitbook.io/tradeoffer-docs"><i className="fa fa-bar-chart"></i> Analytics</a>
            </div>
            <div className="col-md-2">
            <h3>Social</h3>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/TradeOffer_fi"><i className="fa-brands fa-twitter"></i> Twitter</a>
                <a rel="noreferrer" href="https://discord.gg/ubznr4ca4Q" target="_blank"><i className="fa-brands fa-discord"></i> Discord</a>
                <a rel="noreferrer" href="https://t.me/tradeofferfi" target="_blank"><i className="fa-brands fa-telegram"></i> Telegram</a>
            </div>
        </div>
    </div>)
}

export default Footer