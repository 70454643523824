import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type, image}) {
  
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content='website' />
<meta property="og:title" content={title} />
<meta property="og:url" context={window.location.href} />
<meta property="og:image" content={image} />
<meta property="og:description" content={description} />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
<meta name="twitter:creator" content={name} />}
<meta name="twitter:card" content='summary' />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
<meta name="twitter:image" alt="social" content={image}></meta>
{ /* End Twitter tags */ }
</Helmet>
)
}