import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import EnvContext from "../../context/EnvContext";
import TransactionModals from "../TransactionModals/TransactionModals";
import "./SocialSharing.scss"


const SocialSharing = ({transaction}) => {
    const navigate = useNavigate();
    const env = useContext(EnvContext)
    const [isCopied, setIsCopied] = useState(false)
    const [transactionStatus, setTransactionStatus] = useState(0)


    const cancelTransaction = () => {
      setTransactionStatus(0)
    }

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = (copyText) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
          .then(() => {
            // If successful, update the isCopied state value
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    return (

        <div className="social-share">
            <h2>Your offer was created!</h2>
            <div className="clearfix"> 
                <input className={`inputShareLink ${isCopied ? "copied" : "" } ` } disabled value={`${isCopied ? "Copied to clipboard": `${env.baseUrl}/otc/${transaction.uuid}`}`}/>
                <div className="copy" onClick={() => handleCopyClick(`${env.baseUrl}/otc/${transaction.uuid}`)}><i className="fa-regular fa-copy"></i></div>
            </div>
            <div className="btn-holder">
                <button className="btn btn-confirm orders" onClick={() => navigate('/offers/me')}><i className="fa fa-list"></i> View your Orders</button>
                { transaction.signerMetadata.balance && <a target="_blank" rel="noreferrer"
                href={`https://twitter.com/intent/tweet?text=Selling%20${transaction.signerMetadata.balance}%20${transaction.signerMetadata.symbol}%20for%20${transaction.senderMetadata.balance}%20${transaction.senderMetadata.symbol}.%20View%20my%20TradeOffer%20at%20https%3A%2F%2Ftradeoffer.fi%2Fotc%2F${transaction.uuid}&`}
                // href={`https://twitter.com/intent/tweet?text=Selling%20${transaction.signerMetadata.balance}%20${transaction.signerMetadata.symbol}%20for%20${transaction.senderMetadata.balance}%20${transaction.senderMetadata.symbol}.%20View%20my%20TradeOffer%20at%20https%3A%2F%2Ftradeoffer.fi%2Fotc%2F${transaction.uuid}%20%`}
                className="btn btn-confirm twitter"><i className="fa-brands fa-twitter"></i> Share on Twitter</a>}
            </div>
            <TransactionModals transactionStatus={transactionStatus} scheduledTransaction={transaction} cancelTransaction={cancelTransaction} />
        </div>)}

export default SocialSharing