import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useConnectWallet } from '@web3-onboard/react'
import './NavBar.scss'

const NavBar = ({changeNetworkById, currentNetwork}) => {

    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const [isNetworkOpen, setNetworkOpen] = useState(false);
    
    const toggleNetworkOpen = () => {
        setNetworkOpen(!isNetworkOpen);
    }

    return (
    <nav className="navbar navbar-expand-md navbar-dark bg-light">
        <div className="container-fluid">
            <NavLink className="navbar-brand" style={{fontWeight: 200}} to="/">TradeOffer</NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink to="/" className={(state) => state.isActive ? "nav-link active" : "nav-link" }>Create</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/offers/me" className={(state) => state.isActive ? "nav-link active" : "nav-link" }>My Offers</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/mint" className={(state) => state.isActive ? "nav-link active" : "nav-link" }>Mint Token</NavLink>
                    </li>

                    <li className="nav-item">
                        <a href="https://tradeoffer.gitbook.io/tradeoffer-docs/" className="nav-link nonactive" rel="noreferrer" target="_blank">Docs</a>
                    </li>

                    <li className="nav-item hidden-desktop">

                        {currentNetwork === 245022934 && <div className={isNetworkOpen ? 'custom-network-select mobile open': 'custom-network-select mobile'} onClick={toggleNetworkOpen}>
                        <img alt="logo-polygon" src="https://pbs.twimg.com/profile_images/1770100150175719424/vqIfBfI0_400x400.jpg"></img> Neon
                            <i className="fa fa-chevron-down"></i>

                            { isNetworkOpen && <div className="dropd-network" onClick={() =>changeNetworkById(1)}>
                                <p><img alt="logo-ethereum" src="https://www.pngall.com/wp-content/uploads/10/Ethereum-Logo-PNG.png"></img> Ethereum</p>
                            </div>}
                        </div> }

                        {currentNetwork === 1 && <div className={isNetworkOpen ? 'custom-network-select mobile open': 'custom-network-select mobile'} onClick={toggleNetworkOpen}>
                <img alt="logo-ethereum"  src="https://www.pngall.com/wp-content/uploads/10/Ethereum-Logo-PNG.png"></img> Ethereum
                    <i className="fa fa-chevron-down"></i>

                    { isNetworkOpen && <div className="dropd-network" onClick={() =>changeNetworkById(245022934)}>
                        <p> <img alt="logo-polygon" src="https://chainbroker.io/_next/image/?url=https%3A%2F%2Fstatic.chainbroker.io%2Fmediafiles%2Fprojects%2Fneon-evm%2Fneon-evm.jpg&w=1024&q=75"></img> Neon</p>
                    </div>}
                </div>}

                        <button className="btn btn-confirm add-margin-top" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                            {connecting ? 'connecting' : wallet ? 'disconnect' : 'connect wallet'}
                        </button>
                    </li>
                    
                </ul>
            </div>
            <div className="hidden-mobile">
                
                
                {currentNetwork === 1 && <div className={isNetworkOpen ? 'custom-network-select open': 'custom-network-select'} onClick={toggleNetworkOpen}>
                <img alt="logo-ethereum"  src="https://www.pngall.com/wp-content/uploads/10/Ethereum-Logo-PNG.png"></img> Ethereum
                    <i className="fa fa-chevron-down"></i>

                    { isNetworkOpen && <div className="dropd-network" onClick={() =>changeNetworkById(245022934)}>
                        <p> <img alt="logo-polygon" src="https://pbs.twimg.com/profile_images/1770100150175719424/vqIfBfI0_400x400.jpg"></img> Neon</p>
                    </div>}
                </div>}

                {currentNetwork === 245022934 && <div className={isNetworkOpen ? 'custom-network-select open': 'custom-network-select'} onClick={toggleNetworkOpen}>
                <img alt="logo-polygon" src="https://pbs.twimg.com/profile_images/1770100150175719424/vqIfBfI0_400x400.jpg"></img> Neon
                    <i className="fa fa-chevron-down"></i>

                    { isNetworkOpen && <div className="dropd-network" onClick={() =>changeNetworkById(1)}>
                        <p><img alt="logo-ethereum" src="https://www.pngall.com/wp-content/uploads/10/Ethereum-Logo-PNG.png"></img> Ethereum</p>
                    </div>}
                </div> }



                




                <button className="btn btn-confirm" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                        {connecting ? 'connecting' : wallet ? `${wallet.accounts[0].address.slice(0, 15)}..` : 'Connect Wallet'}
                </button>
            </div>
        </div>
    </nav>
    )
}


export default NavBar