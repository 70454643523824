import React from "react"
import { useNavigate } from "react-router-dom";
import './MyOffer.scss'
import moment from "moment"

const MyOffer = (prop) => {
    const navigate = useNavigate();

    const makeHumanReadable = (num, singular) => {
        return num > 0
          ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
          : '';
      }

    const toDaysMinutesSeconds = (totalSeconds) => {
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
        const days = Math.floor(totalSeconds / (3600 * 24));
      
        const minutesStr = makeHumanReadable(minutes, 'minute');
        const hoursStr = makeHumanReadable(hours, 'hour');
        const daysStr = makeHumanReadable(days, 'day');
      
        return `${daysStr}${hoursStr}${minutesStr}`.replace(/,\s*$/, '');
      }

      const isExpired = (obj) => {

        const timeLeftSeconds = (parseInt(obj.expiry) - parseInt(moment.utc().valueOf() / 1000))
        if(timeLeftSeconds < 0) return true
        return false
      }

      const countTime = (expiry) => {
        const timeLeftSeconds = (expiry - parseInt(moment.utc().valueOf()) / 1000)
        let readableTime = toDaysMinutesSeconds(timeLeftSeconds)
        return readableTime
      }


    return(
        <div className="myOffer" onClick={() => navigate(`/otc/${prop.object.uuid}`)}>
            { prop.object.senderAmount > 0 && <p style={{fontWeight: 200}} className="offerP">
                <i className="fa-sharp fa-solid fa-arrow-up-right-from-square"></i> 
                sell <span style={{fontWeight: 600}}> {prop.object.signerMetadata.balance} {prop.object.signerMetadata.symbol} </span> 
                for <span style={{fontWeight: 600}}>{prop.object.senderMetadata.balance} {prop.object.senderMetadata.symbol}</span>
            </p> }

            { prop.object.senderAmount === '0' && <p style={{fontWeight: 200}} className="offerP">
                <i className="fa-sharp fa-solid fa-arrow-up-right-from-square"></i> 
                created a <span style={{fontWeight: 600}}> {prop.object.signerMetadata.balance } {prop.object.signerMetadata.symbol} </span> 
                Airdrop
            </p> }

            <hr/>


            {prop.object.cancelled === 'true' && (<p className="status">status: cancelled</p>)}

            {prop.object.finished === 'true' && (<p className="status">status: filled</p>)}


            {(prop.object.finished === 'false' && prop.object.cancelled === 'false' && !isExpired(prop.object)) && (
            <p className="status">status: available for {countTime(prop.object.expiry)}</p>
            )}
            { prop.object.network === '245022934' && <p className="status">network: neon </p>}
            { prop.object.network === '1' && <p className="status">network: ethereum </p>}

            {prop.object.senderWallet !== '0x0000000000000000000000000000000000000000' && (<p className="createdAt" style={{marginBottom: '0px', marginTop: '4px'}}>whitelisted for {prop.object.senderWallet}</p>)}

            {(prop.object.finished === 'false' && isExpired(prop.object)) && (<p className="status">status: expired</p>)}
            <p className="createdAt">uuid: {prop.object.uuid}</p>
        </div>
    )
}

export default MyOffer
