import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import axios from "axios"
import SwapInput from "../../components/SwapInput/SwapInput";
import moment from "moment"
import './OTC.scss'
import SocialSharing from "../../components/SocialSharing/SocialSharing";
import { ethers} from "ethers"
import TransactionModals from "../../components/TransactionModals/TransactionModals";
import Spinner from "../../components/Spinner/Spinner";
import EnvContext from "../../context/EnvContext";
import SEO from "../../components/SEO/SEO";
import { useConnectWallet } from '@web3-onboard/react'


const erc20ABI = require("../../assets/abis/erc20.json")
const swapABI = require("../../assets/abis/swap.json")

const OTC = ({currentNetwork, changeNetworkById}) => {
    const env = useContext(EnvContext);
    const [scheduledTransaction, setScheduledTransaction] = useState({})
    const [transactionStatus, setTransactionStatus] = useState(0)
    const [userIsOwner, setUserIsOwner] = useState(false)
    const disabled = () => {}
    const [validationError, setValidationError] = useState('')
    const [expired, setExpired] = useState(false)
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [order, setOrder] = useState({})
    const [wrongNetwork, setWrongNetwork] = useState(false)
    const [decryptionLoading, setDecryptionLoading] = useState(false)
    const [decryptedMessage, setDecryptedMessage] = useState('')
    const [timeLeft, setTimeLeft] = useState('')

    const [orderValidation, setOrderValidation] = useState({
        valid: false,
        action: '',
        message: ''
    })

    const [takerBalance, setTakerBalance] = useState('0')
    const [loading, setLoading] = useState(true)
    const [orderNotAvailable, setOrderNotAvailable] = useState(false)
    let { uuid } = useParams();
    const [{ wallet }, connect ] = useConnectWallet()

    const removeOrder = async() => {
        try {
            let swapContractAddress = "0x8fca5f8455ad1e423761ef7a50d62ef407a4a9c0"
            if(currentNetwork === 245022934) {
                swapContractAddress = "0x910ce66b07f26b0c5083f5bc022789206c4f5a8a"
            }
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
            let signer = await ethersProvider.getSigner()     
            let swapContract = new ethers.Contract(swapContractAddress, swapABI, signer)
            const tx = await swapContract.cancel([order.nonce])
            setLoading(true)
            await tx.wait();
            setLoading(false)
            window.location.reload()
        } catch (e) {
            setLoading(false)
        }
    }


    const validateOrder = () => {
        if(wallet === null) {
            setOrderValidation({
                valid: false, 
                message: 'Connect wallet to accept this trade',
                action: 'connect'
            })
            return         
        }

        // user is taker
        if(wallet && wallet.accounts.length > 0 && (wallet.accounts[0].address === order.senderWallet)) {
        // if encrypted note is availabel
            if(order.enableEncryptedNote && order.requiredAcceptEncryptedNote) {
                // if user didnt accept the terms 
                if(decryptedMessage === "") {
                    setOrderValidation({
                        valid: false, 
                        message: 'Sign message to reveal content',
                        action: 'solveNonceChallenge',
                        params: [order.senderNonce, 'sender']
                    })
                    return
                }
                if(acceptTerms === false) {
                    setOrderValidation({
                        valid: false, 
                        message: 'Accept the contents of the message to continue',
                        action: ''
                    })
                    return
                }
            }
        }

        if(order.senderWallet === '0x0000000000000000000000000000000000000000') {
            if(parseFloat(takerBalance) >= parseFloat(order.senderMetadata.balance))  {
                setOrderValidation({
                    valid: true, 
                    message: 'Accept Offer',
                    action: 'takeOrder'
                })
                return 
            } else {
                setOrderValidation({
                    valid: false, 
                    message: 'Not enough balance',
                })
                return 
            }
        } else {
            // whitelisted order
            // not correct address 
            if(wallet && wallet.accounts.length > 0 && wallet.accounts[0].address !== order.senderWallet) {
                setOrderValidation({
                    valid: false, 
                    message: 'Not whitelisted to take this order',
                })
                return 
            }


            // airdrop, no need to check amount
            if(parseFloat(order.senderMetadata.balance ) === 0) {
                setOrderValidation({
                    valid: true, 
                    message: 'Accept Offer',
                    action: 'takeOrder'
                })
                return 
            }

            // not an airdrop
            if((parseFloat(takerBalance) >= parseFloat(order.senderMetadata.balance ))) {
                setOrderValidation({
                    valid: true, 
                    message: 'Accept Offer',
                    action: 'takeOrder'
                })
                return 
            } else {
                setOrderValidation({
                    valid: false, 
                    message: 'Not enough balance',
                })
                return 
            }

        }
    }



    const checkOrder = async () => {
        let swapContractAddress = "0x8fca5f8455ad1e423761ef7a50d62ef407a4a9c0"
        if(currentNetwork === 245022934) {
            swapContractAddress = "0x910ce66b07f26b0c5083f5bc022789206c4f5a8a"
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        let swapContract = new ethers.Contract(swapContractAddress, swapABI, provider)        
        
        let sig = ethers.utils.joinSignature({v: order.v, r: order.r, s: order.s})

        let errorArray = []
        let res = await swapContract.check(
            order.hashedNote,
            order.nonce, 
            order.expiry,
            order.senderWallet,
            order.signerWallet,
            order.signerToken,
            order.signerAmount,
            order.senderToken,
            order.senderAmount,
            sig)

            if(res.length > 0) {
                    if(res === 'EXPIRY_PASSED') errorArray.push('Trade has expired')
                    if(res === 'SIGNER_ALLOWANCE_LOW') errorArray.push('Trade Maker has a low smart contract allowance')
                    if(res === 'SIGNER_BALANCE_LOW') errorArray.push('Trade Maker has not enough funds in wallet')
                    if(res === 'CHAIN_ID_CHANGED') errorArray.push('Chain ID is invalid')
                    if(res === 'SIGNATURE_INVALID') errorArray.push('Invalid signature')
                    if(res === 'NONCE_ALREADY_USED') errorArray.push('User removed order or trade has been taken')
                    if(res === 'UNAUTHORIZED') errorArray.push('Invalid signature')
             }
            

            setValidationError(errorArray[0])
        }   
    

    const cancelTransaction = () => {
        setTransactionStatus(0)
    }


    const sendOrder = async (baseToken, baseAmount, quoteToken, quoteAmount, enabledWhiteList, whiteListAddres, timeAmount, timeUnit, infiniteApproval) => {
        let swapContractAddress = "0x8fca5f8455ad1e423761ef7a50d62ef407a4a9c0"
        if(currentNetwork === 245022934) {
            swapContractAddress = "0x910ce66b07f26b0c5083f5bc022789206c4f5a8a"
        }
        let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
        let quoteCurrencyContract = new ethers.Contract(quoteToken.address, erc20ABI, ethersProvider)
        

        let allowance = await quoteCurrencyContract.allowance(wallet.accounts[0].address, swapContractAddress)
        allowance = ethers.utils.formatUnits(allowance, quoteToken.decimals)

        
        setScheduledTransaction({baseToken, baseAmount, quoteToken, quoteAmount})
    
        if(parseFloat(allowance) < parseFloat(quoteAmount)) {
            // show user information about approving
            if(transactionStatus !== 11) {
                setTransactionStatus(11)
                return 
            } 
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            let signer = await ethersProvider.getSigner()           
            let quoteCurrencyContractSigner = new ethers.Contract(quoteToken.address, erc20ABI, signer)


            let totalSupply = await quoteCurrencyContract.totalSupply()
            totalSupply = totalSupply.toString() + '0'

            // if infinite approval is set, use totalsupply for approval
            // else use just required amount
            let requiredAmount = ethers.utils.parseUnits(quoteAmount, quoteToken.decimals)
            if(infiniteApproval) requiredAmount = totalSupply.toString()

            const tx = await quoteCurrencyContractSigner.approve(swapContractAddress, requiredAmount)
            setTransactionStatus(2)
            await tx.wait();
            setTransactionStatus(5)

        } else { 
            // show user information finalizing transaction
            if(transactionStatus !== 5) {
                setTransactionStatus(5)
                return 
            } 
            // execute transaction
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            let signer = await ethersProvider.getSigner()    
            let swapContract = new ethers.Contract(swapContractAddress, swapABI, signer)
            let sig = ethers.utils.joinSignature({v: order.v, r: order.r, s: order.s})
            
            if(order.requiredAcceptEncryptedNote === false) {
                // if reading is not mandatory dont submit hash
                // order.hashedNote = '0x0000000000000000000000000000000000000000000000000000000000000000'
            }
            

            if(order.senderWallet !== '0x0000000000000000000000000000000000000000') {
                const txRecipient = await swapContract.swapRecipient(
                    order.hashedNote,
                    order.nonce, 
                    order.expiry,
                    order.signerWallet,
                    order.signerToken,
                    order.signerAmount,
                    order.senderToken,
                    order.senderAmount,
                    sig
                )
                setTransactionStatus(6)
                await txRecipient.wait();
                document.location.href = `/?a=finish`;
            } else {
            // if not whitelist
            const tx = await swapContract.swapAnySender(
                wallet.accounts[0].address,
                order.nonce,
                order.expiry,
                order.signerWallet,
                order.signerToken,
                order.signerAmount,
                order.senderToken,
                order.senderAmount,
                sig
            )
            setTransactionStatus(6)
            await tx.wait();
            document.location.href = `/?a=finish`;
            }
        }        
    }   

    const takeOrder = () => {
        const orderFormatted = {
            baseToken: {
                address: order.signerToken,
                decimals: order.signerMetadata.decimals,
                symbol: order.signerMetadata.symbol
            },
            baseAmount: order.signerMetadata.balance,
            quoteToken: {
                address: order.senderToken, 
                decimals: order.senderMetadata.decimals,
                symbol: order.senderMetadata.symbol
            },
            quoteAmount: order.senderMetadata.balance
        }
        sendOrder(orderFormatted.baseToken, orderFormatted.baseAmount, orderFormatted.quoteToken, orderFormatted.quoteAmount)
    }

    const solveNonceChallenge = async (nonce, role) => {
        let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
        let signer = await ethersProvider.getSigner() 
        let signature = await signer.signMessage(nonce)
        // send signature to endpoint
        try {
            setDecryptionLoading(true)
            let res = await axios.post(`${env.backendUrl}/order/message/decrypt?uuid=${uuid}`, {uuid, role, signature})    
            if(res.data.message) {
                setDecryptedMessage(res.data.message)
                setDecryptionLoading(false)
            }
        } catch (e) {
            setDecryptionLoading(false)
            console.log(e)
        }
        
    }

    const getPricing = async () => {
        try {
            let url = `https://api.1inch.io/v5.0/${currentNetwork}/quote?`
            url += `fromTokenAddress=${order.senderToken}`
            url += `&toTokenAddress=${order.signerToken}`
            url += `&amount=${order.senderAmount}`
            
            let res = await axios.get(url)
            console.log(res)
            let tokenExpectedOutput = res.data.toTokenAmount

            let resultAggregator = tokenExpectedOutput / Math.pow(10, order.signerMetadata.decimals)

            let actual = order.signerAmount / Math.pow(10, order.signerMetadata.decimals)
    

            let diff = ((resultAggregator - actual) / actual) * 100
            order.pricingDelta = diff
        } catch (e) {
            console.log(e)
        }
    }

    const invoke = (action, params) => {
        if(action === 'connect') connect()
        if(action === 'takeOrder') takeOrder()
        if(action === 'solveNonceChallenge') solveNonceChallenge(...params)
       
    }

    const makeHumanReadable = (num, singular) => {
        return num > 0
          ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
          : '';
      }

    const acceptTermsChangeHandler = (event) => {
        setAcceptTerms(event.target.checked)
    }

    const toDaysMinutesSeconds = (totalSeconds) => {
        const seconds = Math.floor(totalSeconds % 60);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
        const days = Math.floor(totalSeconds / (3600 * 24));
      
        const secondsStr = makeHumanReadable(seconds, 'second');
        const minutesStr = makeHumanReadable(minutes, 'minute');
        const hoursStr = makeHumanReadable(hours, 'hour');
        const daysStr = makeHumanReadable(days, 'day');
      
        return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
      }

      const countTime = () => {
        const timeLeftSeconds = (order.expiry - parseInt(moment.utc().valueOf() / 1000)) 
        let readableTime = toDaysMinutesSeconds(timeLeftSeconds)
        setTimeLeft(readableTime)
        setTimeout(countTime, 1000)
      }

      const checkIfMaker = (accounts) => {
        if(!accounts || accounts.length === 0) return 
        if(accounts[0].address.toLowerCase() === order.signerWallet) {
            setUserIsOwner(true)
        } else {
            setUserIsOwner(false)
        }
      }

      const checkBalance = async() => {
        try {
            let ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any') 
            let erc20Contract = new ethers.Contract(order.senderToken, erc20ABI, ethersProvider)
            let contractRes = await erc20Contract.balanceOf(wallet.accounts[0].address)
            let balance = ethers.utils.formatUnits(contractRes, order.senderMetadata.decimals)
            setTakerBalance(balance)
            validateOrder()
        } catch (e) {
            console.log("cannot get account balance for taker")
        }
      }

      useEffect(() => {
        if(order.expiry) {
            if(wallet && wallet.accounts) {
                checkIfMaker(wallet.accounts)
                checkBalance()
                
            } 
            setWrongNetwork(false)
            if(parseInt(order.network) !== currentNetwork) {
               setWrongNetwork(true)
            } else {
                checkOrder()
                validateOrder()
                getPricing()
            }
        }
      }, [wallet, order, expired, currentNetwork]) // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        if(!order.expiry) return
        let timeLeft = parseInt(order.expiry) - parseInt(moment.utc().valueOf() / 1000)
        if(timeLeft > 0) {
            setExpired(false)
            countTime()
        } else {
            setExpired(true)
            
        }
        validateOrder()
      }, [order, wallet]) // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        validateOrder()
      }, [takerBalance, decryptedMessage, acceptTerms]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getOrderFromAPI = async () => {
            try {
                if(uuid.length !== 36) setOrderNotAvailable(true) 
                let res = await axios.get(`${env.backendUrl}/order?uuid=${uuid}`)
                if(!res.data.success || !res.data.order.expiry) {
                    setOrderNotAvailable(true) 
                    setLoading(false) 
                } else {
                    console.log(res.data.order)
                    setOrder(res.data.order)
                    setLoading(false)
                }
            } catch (e) {
                console.log(e)
            }
        }    
        if(uuid) getOrderFromAPI()    
    }, [uuid, env.backendUrl])

    return (

    <div className="takerHolder">
        
        { order.memeUrl && order.senderMetadata.balance > 0 &&  <SEO title={`TradeOffer.fi - ${order.senderMetadata.balance} ${order.senderMetadata.symbol} for ${order.signerMetadata.balance} ${order.signerMetadata.symbol}`} description='Swap Illiquid Tokens OTC by creating an offer. Offers can be made available to the public or to a whitelisted address. Share trade links directly to Telegram or Twitter' name='TradeOffer.fi' type='article'  image={order.memeUrl} />}
        { order.memeUrl && order.senderMetadata.balance === '0' && <SEO title={`TradeOffer.fi - ${order.signerMetadata.balance} ${order.signerMetadata.symbol} Airdrop`} description='Swap Illiquid Tokens OTC by creating an offer. Offers can be made available to the public or to a whitelisted address. Share trade links directly to Telegram or Twitter' name='TradeOffer.fi' type='article'  image={order.memeUrl} />}
        {loading && <Spinner/>}
        <TransactionModals transactionStatus={transactionStatus} cancelTransaction={cancelTransaction} scheduledTransaction={scheduledTransaction} sendOrder={sendOrder} />
        {(!loading && orderNotAvailable) && (
            <React.Fragment>
                <div className="unavailable">
                    <div >Order with this UUID does not exist or cannot be retrieved.</div>
                    <a className="btn btn-confirm" href="/" style={{marginTop: '10px' }}>Return to Home</a>
                </div>
            </React.Fragment>
        )}


        {(!loading && !orderNotAvailable) && (<div className="container">

            {(userIsOwner && order) &&  (<div style={{marginBottom: '10px'}} className="col-md-6 offset-md-3">
                { order.senderWallet !== '0x0000000000000000000000000000000000000000' && <p className="whitelist-neutral">Whitelisted for {order.senderWallet} </p>}
                { (!expired && order.cancelled === "false" && order.finished === "false") && (<p className="timeLeft">Trade offer will expire in {timeLeft}</p>)}
                { (expired && order.cancelled === "false" && order.finished === "false") && (<p className="timeLeft expired">Trade offer has expired. Trade no longer possible</p>)}

            </div>)}

            <div className="col-md-6 offset-md-3">
                {wrongNetwork === true && <div className="wrong-network">This order was created for 
                {order.network === "245022934" && <span> Neon </span>}
                {order.network === "1" && <span> Ethereum </span>}
                
                </div>}

                {order.pricingDelta < 0 && <p className="timeLeft" style={{'color': '#5cbb0f'}}>{order.pricingDelta.toFixed(2)}% discount vs 1inch aggregator.{ order.senderWallet === '0x0000000000000000000000000000000000000000' && <span><br></br>We recommend using a private RPC when taking this public offer to prevent frontrunning. <a rel="noreferrer" href="https://docs.flashbots.net/flashbots-protect/rpc/quick-start" target="_blank">Read here how to set this up</a></span>}</p>}
                {order.pricingDelta > 0 && <p className="timeLeft" style={{'color': '#bb2c0f'}}> {order.pricingDelta.toFixed(2)}% premium vs 1inch aggregator. We recommend trading through an aggregator instead of taking this offer.</p>}
                {order.pricingDelta === 0 && <p className="timeLeft">no notable premium or pricing data not available</p>}
            </div>

            {(order.cancelled === "true" || order.finished === "true") && (<div className="col-md-6 offset-md-3">
                {order.cancelled === "true" &&<p className="timeLeft expired" style={{backgroundColor: '#f45e16'}}>Order cancelled by user</p>}
                {order.finished === "true" && <p className="timeLeft expired" style={{backgroundColor: '#01a56a'}}>This Trade Offer was already taken</p>}
            </div>)}

            {(!loading && validationError && validationError.length > 0 && expired === false && order.finished === "false" && order.cancelled === "false") && 
            <div className="col-md-6 offset-md-3">
            {userIsOwner && <p className="timeLeft expired" style={{backgroundColor: '#f45e16'}}>Our systems have detected that this trade is no longer valid for the following reason(s) <br></br>
                {validationError.replace("Trade Maker has", "You have")} 
            </p>}
            {!userIsOwner && <p className="timeLeft expired" style={{backgroundColor: '#f45e16'}}>Our systems have detected that this trade is no longer valid for the following reason(s) <br></br>
                {validationError} 
            </p>}

            </div>}


            {!userIsOwner && (<div className="col-md-6 offset-md-3">            
            { order.senderWallet !== '0x0000000000000000000000000000000000000000' && 
            <React.Fragment>
                { (!wallet || wallet.accounts.length === 0) && 
                <div className="whitelist-neutral">This order can only be taken by whitelisted address. <br></br>Connect your wallet to see if you are whitelisted.</div>
                }

                { (wallet && wallet.accounts.length > 0 && (wallet.accounts[0].address !== order.senderWallet)) && 
                <div className="whitelist-warning">This order can only be taken by whitelisted address {order.senderWallet}</div>
                }

                { (wallet && wallet.accounts.length > 0 && (wallet.accounts[0].address === order.senderWallet)) && 
                <div className="whitelist-confirm">Congratulations, Your wallet was whitelisted for this order</div>
                }

            </React.Fragment>
            }


            { (!expired && order.finished === "false" && order.cancelled === "false") &&   (<p className="timeLeft">Trade offer will expire in {timeLeft}</p>)}
            { expired === true && (<p className="timeLeft expired">Trade offer has expired. Trade no longer possible</p>)}


            <div className="offerTakerHolder">
  
                { order.enableEncryptedNote === true && wallet && wallet.accounts.length > 0 && (wallet.accounts[0].address === order.senderWallet) && <div>
                    { order.requiredAcceptEncryptedNote === false && order.finished === "false" && <p className="note-inform">Offer creator has attached a private note to this offer.</p>}
                    { order.requiredAcceptEncryptedNote === true && order.finished === "false" && <p className="note-inform">Offer creator has attached a private note to this offer. You are required to review the message and accept it's content before you are
                        allowed to take the order.
                    </p>}
                    { order.finished === "true" && order.requiredAcceptEncryptedNote && <p className="note-inform">A secret message was added to this order. A sha256 hash of the message was stored on the blockchain.</p>}
                    { order.finished === "true" && order.requiredAcceptEncryptedNote === false && <p className="note-inform">A secret message was added to this order and was destroyed upon acceptance.</p>}


                    {order.enableEncryptedNote && decryptedMessage.length === 0 && order.finished === 'false' && <div className="note-locked">
                        <p className="hidden">Sign message with your wallet to reveal the actual contents of this message. Manipulating the CSS doesn't work, this is just a placeholder for pleasure. Thanks for visiting!  (does not require a transaction).</p>
                        { decryptionLoading === false && <button className="reveal-btn" onClick={() => solveNonceChallenge(order.senderNonce, 'sender')}>Sign message with your wallet to reveal content</button>}
                        {decryptionLoading && <Spinner/>}
                        {decryptionLoading && <br/>}
                    </div>}


                    {order.enableEncryptedNote && decryptedMessage.length > 0 && <div className="note-locked">
                        <p className="revealed">{decryptedMessage}</p>
                    </div>}
                    
                    {order.enableEncryptedNote && decryptedMessage.length > 0 && order.requiredAcceptEncryptedNote &&  <div className="legal-box">
                     <p><input type="checkbox" checked={acceptTerms} onChange={acceptTermsChangeHandler} />I have read the message above, understand the contents of this message and agree with the implications of the content. By clicking this checkbox I agree to all the terms of this message.</p>
                    </div>
                    }

                </div>}

                { order.senderAmount !== "0" && 
                <div>
                    <p className="lab">You will send</p>
                    {order.expiry && (<SwapInput toggleSelection={disabled} canEdit={false} setActiveType="base" setAmount="0" token={order.senderMetadata }/>)}
                    {takerBalance && <p className="small-balance">{takerBalance} available</p>}
                </div>
                }
                { order.senderAmount === "0" && wallet && wallet.accounts[0].address === order.senderWallet && 
                    <p className="airdrop">You received an offer containing the token listed below. </p>
                }

                { order.senderAmount === "0" && !wallet && 
                    <p className="airdrop">Offer contains airdrop with the token listed below. </p>
                }

                <p className="lab sec">You will instantly receive</p>
                {order.expiry && (<SwapInput toggleSelection={disabled} canEdit={false} setActiveType="base" setAmount="0" token={order.signerMetadata}/>)}
                <p className="lab fees">Protocol Fee: 0.00%</p>
            </div>


                { !validationError  && 
                 <React.Fragment>

                { !expired && (<div className="terms-holder">
                    { order.signerMetadata.contractVerified }
                { !order.signerMetadata.contractVerified && <p className="small-address">{order.signerMetadata.symbol} token address you will receive: {order.signerToken} <br></br>Ensure that this is the token you are expecting and not a fraudulent replica.</p>}
                { !order.senderMetadata.contractVerified && <p className="small-address">{order.senderMetadata.symbol} token address you are sending: {order.senderToken} <br></br>Ensure that this is the token you want to send and not a fraudulent replica.</p>}
                <p className="terms">By confirming to order you understand that swaps are fully decentralized and non-custodial. TradeOffer DAO will never take custody of the traded assets. </p>

                
                <button className={orderValidation.valid ? 'btn btn-confirm' : 'btn btn-confirm dis'} onClick={() => invoke(orderValidation.action, orderValidation.params)}>{orderValidation.message}</button>

                </div>)}
                </React.Fragment>}
                             
            </div>)}

            {userIsOwner && (<div className="col-md-6 offset-md-3">
                
                { order.enableEncryptedNote === true  && <div>
                    { order.requiredAcceptEncryptedNote === false && order.finished === "false" && <p className="note-inform">You have attached a private note to this offer.</p>}
                    { order.requiredAcceptEncryptedNote === true && order.finished === "false" && <p className="note-inform">You have attached a private note to this offer. The offer taker is required to review the message and accept it's content before you are
                        allowed to take the order.
                    </p>}
                    { order.finished === "true" && order.requiredAcceptEncryptedNote && <p className="note-inform"><br/>A secret message was added to this order. A sha256 hash of the message was stored on the blockchain.</p>}
                    { order.finished === "true" && order.requiredAcceptEncryptedNote === false && <p className="note-inform"><br/>A secret message was added to this order and was destroyed upon acceptance.</p>}


                    {order.enableEncryptedNote && decryptedMessage.length === 0 && order.finished === 'false' && <div className="note-locked">
                        <p className="hidden">Sign message with your wallet to reveal the actual contents of this message. Manipulating the CSS doesn't work, this is just a placeholder for pleasure. Thanks for visiting!  (does not require a transaction).</p>
                        { decryptionLoading === false && <button className="reveal-btn" onClick={() => solveNonceChallenge(order.signerNonce, 'signer')}>Sign message with your wallet to reveal content</button>}
                        {decryptionLoading && <Spinner/>}
                        {decryptionLoading && <br/>}
                    </div>}


                    {order.enableEncryptedNote && decryptedMessage.length > 0 && <div className="note-locked">
                        <p className="revealed">{decryptedMessage}</p>
                    </div>}
                    

                </div>}


                 {! expired && (<div style={{backgroundColor: '#222220', marginTop: '20px', color: 'white', padding: '20px'}}>
                    <SocialSharing transaction={{...order, uuid}}/>
                </div>)}


                <div className="offerTakerHolder">
                    { order.senderAmount !== "0" && 
                    <div>
                    <p className="lab" >Upon trade acceptance you will instantly receive</p>
                    {order.expiry && (<SwapInput toggleSelection={disabled} canEdit={false} setActiveType="base" setAmount="0" token={order.senderMetadata }/>)}
                    </div>}


                    { order.senderAmount === "0" && userIsOwner && 
                    <div>
                    <p className="lab" >Upon trade acceptance you will automatically airdrop.</p>
                    {order.expiry && (<SwapInput toggleSelection={disabled} canEdit={false} setActiveType="base" setAmount="0" token={{...order.signerMetadata}}/>)}
                    </div> }

                    { order.senderAmount !== "0" && 
                    <div>
                    <p className="lab" >Upon trade acceptance you will automatically send</p>
                    {order.expiry && (<SwapInput toggleSelection={disabled} canEdit={false} setActiveType="base" setAmount="0" token={{...order.signerMetadata}}/>)}
                    </div> }
                </div>


                { (!expired && order.finished === "false" && order.cancelled === "false") && (<div className="terms-holder">
                <p className="terms">By confirming to order you understand that swaps are fully decentralized and non-custodial. TradeOffer DAO will never take custody of the traded assets. </p>
                <button className="btn btn-confirm" onClick={() => removeOrder()}>Remove Order</button>
                </div> )}
            </div>)}

        </div>)}
    </div>)
}

export default OTC