
import React from "react"
import ReactDom from "react-dom/client"
import App from "./components/App"
import './styles/global.scss'
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"

const root = ReactDom.createRoot(document.getElementById("root"))
root.render(
        <BrowserRouter>
                <ScrollToTop />
                <App/>
        </BrowserRouter>
)
