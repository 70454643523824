import './TokenFactory.scss'
// import { useConnectWallet } from '@web3-onboard/react'
import { ethers} from "ethers"
import React, { useState } from 'react';
import { useConnectWallet } from '@web3-onboard/react'
import Spinner from "../../components/Spinner/Spinner"

const TokenFactory = ({currentNetwork}) => {
    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [supply, setSupply] = useState('');
    
    const contractABI = require('./TokenFactory.json').abi;
    const bytecode = require('./TokenFactory.json').bytecode;


    const [{ wallet }, connect] = useConnectWallet()
    const [loading, setLoading] = useState(false)
    const [deployedAddress, setDeployedAddress] = useState('');
    const [warningShow, setWarningShow] = useState(false);

    // const [{ wallet }, connect ] = useConnectWallet()
    const deployToken = async () => {
        try {
            // Set up a provider
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();

            // Create a contract factory
            const TokenFactory = new ethers.ContractFactory(contractABI, bytecode, signer);

            setLoading(true)
            // Deploy the contract
            const token = await TokenFactory.deploy(name, symbol, 18, supply);
            await token.deployed();

            console.log(`Token deployed to: ${token.address}`);
            setDeployedAddress(token.address);
            setLoading(false)
        } catch (error) {
            console.error('Error deploying token:', error);
        }
    };

    return(
    <div class="page">
        <div class="container forming">
            { !loading && <div>
                <h3 class="title">Mint new token
                {currentNetwork === 245022934 && <span> (Neon EVM </span>} 
                {currentNetwork === 1 && <span >(Ethereum </span>}
                
                ERC20)</h3>
                <p>The supply will be minted to your connected wallet address</p>

                <div class="inputHolder">
                    <label>Token Symbol</label><br></br>
                    <input placeholder="SYMBOL" onChange={e => setSymbol(e.target.value)} />
                    <p><small>symbol/ticker of your token (recommended max 5 characters)</small></p>
                </div>

                <div class="inputHolder">
                    <label>Token Name</label><br></br>
                    <input placeholder="TOKEN NAME" onChange={e => setName(e.target.value)} />
                    <p><small>complete name of your token</small></p>
                </div>

                <div class="inputHolder">
                    <label>Total Supply</label><br></br>
                    <input type="number" placeholder="100000" onChange={e => setSupply(e.target.value)} /><br></br>
                    <p><small>amount of tokens to mint (don't have to take into account decimals)</small></p>
                </div>

                { (name.length > 0 && symbol.length > 0 && supply.length > 0) && <div class="inputHolder">
                    <button className="btn btn-confirm" onClick={deployToken}>Mint Token</button>
                    <p><small>contract fee: 0.01% of supply</small></p>
                </div>}

                { wallet === null && <button className="btn btn-confirm" onClick={() => connect()} >Connect Wallet</button>}

                { warningShow === true && <p class="warn">Please fill out the entire form</p>}

                { (wallet !== null &&  (name.length < 1 || symbol.length < 1 || supply.length < 1)) && <div class="inputHolder">
                    <button className="btn btn-confirm" onClick={() => setWarningShow(true)} >Mint New Token</button>
                    <p><small>0.01% of supply</small></p>
                </div>}
            </div>}

            { loading && 
            <h5>Creating Token</h5>}

            { loading && 
            
            <Spinner/> }

            {deployedAddress && <p>Deployed Contract Address: <a href={`https://etherscan.io/address/${deployedAddress}`}>{deployedAddress}</a></p>}
        </div>
        
    </div>)
}

export default TokenFactory